@import '@angular/material/prebuilt-themes/deeppurple-amber.css';

/* You can add global styles to this file, and also import other style files */
html, body {
    height: 100%;
    padding: 0;
    margin: 0;
}

body
{
    margin: 0;
    font-family: "Roboto", "Helvetica Neue", sans-serif;
    flex-direction: column;
    display: flex;
}

.admin-tab-header
{
    background-color: #ffffffe8;
    border-bottom: 1px solid #c5c5c5;
    padding: 24px 0 0 32px;
    display: flex;
    flex-direction: column;
    gap: 8px
}


.loading-shade-local
{
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.15);
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
}

.loading-shade
{
    position:fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.15);
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
}

.loading-spinner-container
{
    border: 1px solid #e3e3e3;
    border-radius: 8px;
    background-color: white;
    padding: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

/* These are for map-styling which doesn't know that the Angular Template is there */
.info-window-notification
{
    font-weight: bold;
}

.info-window-icon
{
    height: 24px;
    width: 24px;
}

.info-window-info-cell-header
{
    text-align: right;
}
/* End of map styling */

.mat-progress-spinner circle, .mat-spinner circle {
    stroke: rgb(244, 115, 32);
}

h3
{
    color: #0000008c;
}

.mat-mdc-button-base { line-height: normal; }

h2
{
    display: block;
    font-size: 1.5em;
    margin-block-start: 0.83em;
    margin-block-end: 0.83em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
    font-family: Roboto, "Helvetica Neue", sans-serif;
    margin: 0
}

